<template>
  <v-row justify="center">
    <v-dialog
      content-class="dialog"
      max-width="484"
      min-height="205"
      :value="show"
      @click:outside="$emit('closed')"
    >
      <v-card>
        <v-textarea
          class="input"
          rows="3"
          auto-grow
          color="#11ACED"
          outlined
          placeholder="Введите электронные адреса сотрудников, которым необходимо выслать приглашение (можно пригласить до 100 сотрудников одновременно)"
          name="name"
          type="text"
          counter="100"
          :counter-value="() => convertInputToArray.length"
          :rules="[rules.required]"
          v-model="input"
        ></v-textarea>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            style="height: 42px"
            color="black"
            text
            @click="$emit('closed')"
          >
            Отменить
          </v-btn>

          <v-btn class="submin-btn" color="#4CAF50"  :disabled="!valid" @click="acceptEmails"> ОК </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["show"],
  data() {
    return {
      input: "",
      emails: [],
      emailRegex: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
      rules: {
        required: (value) => !!value || "Обязательное поле",
        email: (v) =>
          v.forEach((email) => {
            /.+@.+/.test(email) || "Ошибка в E-mail";
          }),
      },
    };
  },
  methods: {
    acceptEmails() {
      if (this.valid) {
        this.$emit("accepted", this.convertInputToArray);
        this.input = ''
      }
    },
  },
  computed: {
    // перевод текста в массив
    convertInputToArray() {
      let rowInput = this.input.split(/[\s,;]/).map((word) => {
        return word.trim();
      });
      // убираем пустые элементы, если таковые есть
      return rowInput.filter((item) => {
        return item.length !== 0;
      });
    },
    valid() {
      let valid = true;
      if(this.convertInputToArray.length <= 0) valid = false;
      this.convertInputToArray.forEach((email) => {
        if (!this.emailRegex.test(email)) {
          valid = false;
        }
      });
      return valid;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  .v-card {
    width: 484px !important;
    min-height: 205px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start !important;
    padding: 34px 38px 26px 39px;
    &__actions {
      align-self: flex-end;
      padding: 0 !important;
      margin-top: 17px;
    }
  }
  .input {
    width: 100%;
  }
  .submin-btn {
    height: 42px;
    color: white !important;
    &.v-btn--disabled{
      background: #7B7B7B !important;
    }
  }
}
</style>