<template>
  <div class="users">
    <div class="users-wrapper">
      <!-- навигац. цепочка -->
      <div class="nav-chain">
        <div class="chain">
          <div class="link">Пользователи</div>
          <!-- кнопка вызова модалки добавления пользователей -->
          <v-btn
            color="white"
            class="btn"
            v-show="users.length > 0"
            @click="newInvitationShow = true"
          >
            <v-img
              alt="login"
              contain
              src="@/assets/plus.svg"
              transition="scale-transition"
              width="37"
            /><span class="btn__text">Добавить</span>
          </v-btn>
        </div>
      </div>
      <!-- таблица -->
      <div class="table-wrapper" v-show="users.length > 0">
        <v-simple-table class="table">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  class="sticky-header text-left"
                  :class="scrollPosition >= 145 ? 'shadow' : null"
                >
                  Электронная почта
                </th>
                <th
                  class="sticky-header text-left"
                  :class="scrollPosition >= 145 ? 'shadow' : null"
                >
                  Авторизация
                </th>
                <th
                  class="sticky-header text-left"
                  :class="scrollPosition >= 145 ? 'shadow' : null"
                >
                  Дата авторизации
                </th>
                <th
                  class="sticky-header text-left"
                  :class="scrollPosition >= 145 ? 'shadow' : null"
                >
                  Доступ
                </th>
                <th
                  class="sticky-header text-left"
                  :class="scrollPosition >= 145 ? 'shadow' : null"
                >
                  Персональная ссылка
                </th>
                <th
                  class="sticky-header text-left"
                  :class="scrollPosition >= 145 ? 'shadow' : null"
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in users.slice(0, sliceValue)" :key="item.id">
                <td>{{ item.email }}</td>
                <td>{{ item.is_authorized ? "Да" : "Нет" }}</td>
                <td>
                  {{
                    item.authorized_at
                      ? convertedDate(item.authorized_at.split("T")[0])
                      : null
                  }}
                </td>
                <td>
                  <v-switch
                    v-model="item.is_active"
                    color="#29BFFE"
                    class="switch"
                    @change="changeActivation(item.id, item.is_active)"
                    :disabled="!item.is_authorized"
                    hide-details
                  ></v-switch>
                </td>
                <td>
                  {{
                    item.invite_link
                      ? item.invite_link
                      : null
                  }}
                </td>
                <td>
                  <v-btn
                    color="#29BFFE"
                    class="resend-btn"
                    @click="resendInvitation(item.id)"
                    >Пригласить</v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
    <!-- если нет категорий созданных -->
    <div class="noCards" v-show="users.length === 0 && !newInvitationShow">
      <div class="text">
        Для отправки приглашения пользователю нажмите ”Добавить”
      </div>
      <!-- кнопкa вызова модалки добавления пользователей -->
      <v-btn color="white" class="btn" @click="newInvitationShow = true">
        <v-img
          alt="login"
          contain
          src="@/assets/plus.svg"
          transition="scale-transition"
          width="37"
        /><span class="btn__text">Добавить</span>
      </v-btn>
    </div>
    <add-users-modal
      :show="newInvitationShow"
      @closed="newInvitationShow = false"
      @accepted="inviteEmpoyees($event)"
    />
    <invite-info-modal
      :show="inviteInfoShow"
      @closed="inviteInfoShow = false"
    />
  </div>
</template>


<script>
import axios from "axios";
import addUsersModal from "@/components/Users/addUsersModal";
import inviteInfoModal from "@/components/Users/inviteInfoModal";
export default {
  components: { addUsersModal, inviteInfoModal },
  data() {
    return {
      // страница пагинации
      // page: 1,
      // size: 10,

      users: [],
      sliceValue: 50,
      scrollPosition: null,
      newInvitationShow: false,
      inviteInfoShow: false,
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
      /* Если высота окна + высота прокрутки больше или равны высоте всего документа, то  */
      if (
        window.innerHeight + window.scrollY >= document.body.clientHeight &&
        this.users.length > this.sliceValue
      ) {
        this.sliceValue += 50;
      }
    },
    inviteEmpoyees(data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/users/", { emails: data })
          .then((resp) => {
            this.newInvitationShow = false;
            this.inviteInfoShow = true;
            this.getUsers();
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    resendInvitation(id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/users/${id}/resend_invite`)
          .then((resp) => {
            this.inviteInfoShow = true;
            resolve(resp);
          })
          .then(() => {
            this.getUsers();
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    changeActivation(id, value) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/users/${id}/change_activation`, {
            is_active: value,
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    getUsers() {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/`)
          .then((resp) => {
            this.users = resp.data;
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
            console.log(err);
          });
      });
    },
    convertedDate(rowDate) {
      let dateParts = rowDate.split("-");
      return [dateParts[2], dateParts[1], dateParts[0]].join(".");
    },
  },

  watch: {
    inviteInfoShow() {
      if (this.inviteInfoShow) {
        setTimeout(() => (this.inviteInfoShow = false), 4000);
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  destroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },
  // computed: {
  //   // отображение через пагинацию
  //   paginationData() {
  //     let start = (this.page - 1) * this.size,
  //       end = start + this.size;
  //     return this.users.slice(start, end);
  //   },
  //   // узнать сколько страниц пагинации
  //   pageCount() {
  //     let l = this.users.length,
  //       s = this.size;
  //     return Math.ceil(l / s);
  //   },
  // },
  created() {
    this.getUsers();
  },
};
</script>


<style lang="scss" scoped>
.users {
  position: relative;
  height: 100%;
  &-wrapper {
    padding: 0 170px 19px 170px;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
.nav-chain {
  padding-top: 0;
  padding-bottom: 0;
  z-index: 11;
}
.chain {
  padding-top: 40px;
  padding-bottom: 58px;
  // text-indent: 12px;
  font-size: 14px;
  line-height: 21px;
  color: rgba(54, 66, 165, 0.65);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.v-data-table .sticky-header {
  position: sticky;
  top: 70px;
  background: white;
  z-index: 2;
  &.shadow {
    box-shadow: 3px 5px 4px 0px rgba(0, 0, 0, 0.2) !important;
  }
}

.table {
  &-wrapper {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    background: white;
    margin-bottom: 75px;
  }

  th {
    padding: 23px !important;
  }
  td {
    padding: 0 23px !important;
  }
  .switch {
    margin-top: 0 !important;
  }
  .resend-btn {
    max-width: 84px !important;
    max-height: 26px !important;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    color: white;
  }
}

.noCards {
  z-index: 10;
  position: absolute;
  width: 100%;
  background: rgba(108, 108, 108, 0.15);
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text {
    max-width: 408px;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #333333;
    margin-bottom: 32px;
  }
}
.btn {
  width: 167px;
  height: 47px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #3642a5 !important;
    margin-left: 11px;
    margin-right: 11px;
  }
}
.breadcrumbs {
  display: flex;
  flex-direction: row;
  .divider {
    color: rgba(0, 0, 0, 0.38);
    font-size: 14px;
    margin-right: 12px;
    margin-left: 12px;
  }
  .link {
    color: rgba(54, 66, 165, 0.65);
    font-size: 14px;
    line-height: 21px;
    text-indent: 12px;
    text-decoration: none;
    cursor: pointer;
    &.disabled {
      cursor: default;
      color: #3642a5;
      font-size: 14px;
      line-height: 21px;
    }
  }
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #eff2f5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #29bffe;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #11aced;
}
</style>