<template>
  <v-row justify="center">
    <v-dialog
      content-class="dialog"
      max-width="550"
      min-height="180"
      :value="show"
      @click:outside="$emit('closed')"
    >
      <v-card>
        <div class="title">Отправка приглашений сотрудникам завершена.</div>
        <div class="subtitle">
          На указанные почты будут отправлены письма с приглашениями. В письме будет размещаться ссылка с уникальным кодом и одноразовый уникальный пароль.
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: ["show"],
};
</script>

<style lang="scss" scoped>
.dialog {
  .v-card {
    width: 550px !important;
    min-height: 180px !important;
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    padding: 25px;
    &__actions {
      align-self: flex-end;
      padding: 0 !important;
      margin-top: 17px;
    }
  }
  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    text-align: left;
    color: #333333;
  }
  .subtitle {
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    margin-top: 16px;
  }
}
</style>